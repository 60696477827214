import React from "react"
import tw from "twin.macro"

const Container = tw.div`
max-w-xs m-6 lg:w-1/3 rounded-lg shadow bg-gray-100 cursor-pointer hover:shadow-lg hover:bg-gray-200 overflow-hidden
`
const Card = ({ children, ...props }) => {
  return <Container>{children}</Container>
}

export default Card
