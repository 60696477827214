import React from "react"
import tw, { styled } from "twin.macro"
import { navigate } from "gatsby"
import Moment from "react-moment"

const Item = tw.li`
h-full
`
const PublishedOn = styled.p`
  ${tw`text-sm text-left font-semibold text-gray-800 mt-2`};
`

const Image = tw.img`
h-64 overflow-hidden rounded-t-lg object-cover
`

const ItemMetadata = tw.div`
m-2 h-auto
`
const Title = styled.h2`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tw`h-10 leading-tight text-left font-bold text-gray-800 mb-3`};
`
const Summary = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tw`leading-tight text-left text-gray-800 mb-3`};
`

const ItemSummary = ({ ...props }) => {
  const clickHandler = slug => {
    navigate("/blog/" + slug)
  }

  return (
    <Item key={props.id} onClick={() => clickHandler(props.slug)}>
      <Image src={props.image + "?width=300"} />
      <ItemMetadata>
        <Title>{props.title}</Title>
        <Summary>{props.summary}</Summary>
        <PublishedOn>
          Published on: <Moment format="LL">{props.updatedOn}</Moment>
        </PublishedOn>
      </ItemMetadata>
    </Item>
  )
}

export default ItemSummary
