import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import ArticleList from "../components/articleList"
import TextPanel from "../components/textPanel"
import SEO from "../layout/seo"
import Layout from "../layout"

const MainContainer = tw.div`
container mx-auto  mt-12 md:mt-24 px-3
`

const Blog = () => {
  const data = useStaticQuery(graphql`
    query BlogHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "Blog" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data
  return (
    <>
      <SEO title="The Innovate Crowd Blog Page" />
      <Layout>
        <MainContainer>
          <TextPanel heading={header.heading} subHeading={header.subHeading} />
          <ArticleList />
        </MainContainer>
      </Layout>
    </>
  )
}

export default Blog
