import React from "react"
import tw from "twin.macro"
import Card from "./primitives/card"
import ItemSummary from "./itemSummary"
import { graphql, useStaticQuery } from "gatsby"

const ListContainer = tw.div`
max-w-screen-xl container pt-0 md:pt-12 flex justify-center
`

const List = tw.ul`
flex flex-col flex-wrap md:flex-row md:justify-center
`

const ArticleList = ({ heading, subHeading, ...props }) => {
  const data = useStaticQuery(graphql`
    query AllBlogPosts {
      webinyHeadlessCms {
        listBlogPosts {
          data {
            id
            updatedOn
            title
            image
            summary
            slug
          }
        }
      }
    }
  `)

  const blogPosts = data.webinyHeadlessCms.listBlogPosts.data

  return (
    <ListContainer>
      <List>
        {blogPosts.map(post => {
          return (
            <Card key={`post-${post.id}`}>
              <ItemSummary
                updatedOn={post.updatedOn}
                image={post.image}
                title={post.title}
                slug={post.slug || post.id}
                summary={post.summary}
              />
            </Card>
          )
        })}
      </List>
    </ListContainer>
  )
}

export default ArticleList
